const SET_EXAM_STATE = 'SET_EXAM_STATE';
const SET_QUESTIONS = 'SET_QUESTIONS';
const SET_EXAM_QUESTIONS = 'SET_EXAM_QUESTIONS';
const SET_EXAM_TIME = 'SET_EXAM_TIME';
const SET_EXAM_START_DATE = 'SET_EXAM_START_DATE';
const SET_EXAM_USER_ANSWER = 'SET_EXAM_USER_ANSWER';
const SET_EXAM_END_DATE = 'SET_EXAM_END_DATE';

const START_EXAM = 'START_EXAM';
const END_EXAM = 'END_EXAM';
const READ_EXAM = 'READ_EXAM';
const CHANGE_ANSWER = 'CHANGE_ANSWER';
const CLEAR_EXAM = 'CLEAR_EXAM';

export {
  SET_EXAM_STATE,
  SET_QUESTIONS,
  SET_EXAM_QUESTIONS,
  SET_EXAM_TIME,
  SET_EXAM_START_DATE,
  SET_EXAM_USER_ANSWER,
  SET_EXAM_END_DATE,
  START_EXAM,
  END_EXAM,
  READ_EXAM,
  CHANGE_ANSWER,
  CLEAR_EXAM,
};
